import type { BasePageContentAPI } from '@backmarket/http-api/src/api-specs-content/models/base-page-content'
import { BLOCKS } from '@contentful/rich-text-types'

const HOME_FALLBACK_DATA: BasePageContentAPI = {
  id: '3WflFt0AhP0k5agSAfqagd',
  name: 'home-duplicated',
  blocks: [
    {
      id: '5r6vHlQ5zYwohJSKpmqKHQ',
      type: 'Carousel',
      props: {
        technicalName: 'Carousel - BELGIUM',
        slides: [
          {
            id: '1epfneE8JvHMgJtzYBo6GR',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/PGin61HPNgy2daF9mj1QV/c1bde681d26ec4dfe18e0101d6c7cd55/GENERIC_HPBANNERS_iPhone13_Desktop_FR.jpeg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/36HOiWFM3IfDRY9fPWXoyZ/2575401d3fff1a02a34bba2430bcfe5c/GENERIC_HPBANNERS_iPhone13_Mobile_FR.jpeg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'BE - iPhone 13 x Generic',
            },
            link: {
              href: 'https://www.backmarket.be/fr-be/l/iphone-13/14c5d644-a43a-42a8-b8f3-37d5af3936fc',
            },
          },
          {
            id: '5Ed3YAN675oFDgkugmvkjw',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/624ILD1B7kZKNxTaWpkba0/72e3860711c78e28dcd2ed2f429c16d3/HP_Desktop-France.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/4A4kbgXteJcH08rdPjyQu9/713250abeedef9b9018a3eac98b9b8ee/HP_Mobile-_France.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'BE - Good Deals',
            },
            link: {
              href: 'https://www.backmarket.be/fr-be/e/good-deals',
            },
          },
          {
            id: '6CDvUGul11wWURtdalgGtv',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/32QzJ5YZMg3wcreJothsal/392e03896aa68c120bbe8a0711fb1140/desktop-FR.jpeg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/22Kca3CqP9Lif2jDrIRNnb/cad1b8b0dbf1b9d3e8a7b83229337751/mobile-FR2__1_.jpeg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'BE- Backup 2024',
            },
          },
          {
            id: '1JHX6T7SjvLgDV4NzxirRN',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3HY43D9kD1EH0uuXRm0KTu/40f14247cc91965f7e9a7b130163a7f2/Copy_of_VISION_Desktop_FR.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/lv5aqe7h0mgZCy97DziYi/a4f3256cd5b4d0fc195c00ed90e08743/Copy_of_VISION_Mobile_FR.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'BE - Vision ',
            },
            link: {
              href: 'https://www.backmarket.be/fr-be/about-us',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '6iV2Wz8DZmN0BLIBfTrmuP',
      type: 'Categories',
      props: {
        title: 'Catégories poids lourds',
        subtitle:
          'Les appareils reconditionnés encore plus populaires que Teddy Riner',
        categories: [
          {
            id: '4JTtxWDCTqcitklHABGdmy',
            title: 'Smartphones',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3cnra6im5fWnEyRwS71Uvg/8e1637792eaf2a3fc7dbd7a10fbf2e03/iphone_12.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'iPhone 12',
            },
            tagline: 'Encore plus intelligents que les téléphones neufs.',
            link: {
              href: 'https://www.backmarket.be/fr-be/l/smartphones/6c290010-c0c2-47a4-b68a-ac2ec2b64dca',
            },
          },
          {
            id: '4keqaWL7qk3mNVzJ7tTyhE',
            title: 'MacBook',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/27ZSBQZSKhuRA2NGVNXJqz/0e3b88642ff6ed7c53fcf59214ea3f44/MacBook_Air_M1_2020.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'MacBook Air M1',
            },
            tagline: 'Plus forts que McGregor, plus design que Mac Lesggy.',
            link: {
              href: 'https://www.backmarket.be/fr-be/l/macbook-reconditionne/297f69c7-b41c-40dd-aa9b-93ab067eb691',
            },
          },
          {
            id: '1v3u4S3y8cezlwbMaLEZT7',
            title: 'Tablettes',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3q7nQi6Q5LKO60xX5RPieH/9d4add3970c66c0757f14ba894f93478/ipad_pro_2019.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'iPad Pro',
            },
            tagline:
              "Jusqu'à -40%. Ça laisse de quoi s'offrir un abonnement Netflix.",
            link: {
              href: 'https://www.backmarket.be/fr-be/l/tablettes-reconditionnees/5a3cfa21-b588-49b1-b4e9-2636bec68ada',
            },
          },
          {
            id: '1bZWzM22fonFGNROCZH53E',
            title: 'AirPods',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6UBdFdF7X6S0nGCexTB9pw/852cda0b40a1e2bec85fcf65f6ead8c1/308532_7b427385-34fc-40f5-9e68-73890ed77684-thumb.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'AirPods & Earphones',
            },
            tagline:
              "Le gadget le plus cool depuis l'invention des  Ariel 3-en-1 pods.",
            link: {
              href: 'https://www.backmarket.be/fr-be/l/airpods/5091ca6b-c36c-4d50-b644-fb3c5bdd5f16',
            },
          },
          {
            id: '7emA1j0ta3jr2oXpOiEWCz',
            title: 'Ordinateurs Portable',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4rah4soJKbLIZaX0YrEIfQ/80fdfd69be6d026161c5e28e9ae4c494/Asus_Zen_book_Pro.png',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Laptop windows',
            },
            tagline: 'Pour les inconditionnels de Christian Clavier.',
            link: {
              href: 'https://www.backmarket.be/fr-be/l/ordinateur-portable-reconditionne/630dab14-5051-49b9-bc7b-bb20876d4850',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '4DA4kNvJo4Fo7NgfVSbXOR',
      type: 'Categories',
      props: {
        title: 'Autres catégories',
        subtitle: 'Rien ne se perd, rien ne se crée, tout se reconditionne',
        categories: [
          {
            id: '5TMbMJQRPRSIFl5EiKsF29',
            title: 'Appareils Photo',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/51pMifL5BxMUDHSVwyAeBE/fe18ecdcfd1318b89f5fc6035f11e8f9/129798_a2cc9fc1-07fb-47cf-8613-ff82a354a3f8-thumb.jpg',
              width: 400,
              height: 400,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/5T5ucA4BO8D0FC6BUnYB8a/995b9be9e522c47247eb05350b188883/esfNikon.jpeg',
              widthMobile: 200,
              heightMobile: 200,
              alt: 'ES- BF Nikon',
            },
            tagline: 'La vente "flash" la plus longue de l\'histoire.',
            link: {
              href: 'https://www.backmarket.be/fr-be/l/appareil-photo-reconditionne/6983887c-5306-4753-964b-eb04f38b59fd',
            },
          },
          {
            id: '4RmLT3W8F32ehauKjllZI9',
            title: 'Samsung Galaxy',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/c3phFqsPsCEl7dJiGDX5d/d0118eb51df43c7645b5423e83369ce6/S20STORE.png',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Samsung s20',
            },
            tagline: 'Notre sélection intersidérale.',
            link: {
              href: 'https://www.backmarket.be/fr-be/l/samsung/99760870-ed75-482f-a626-2b4f964c55ae',
            },
          },
          {
            id: '2xdHnNpWTo3ncxyTBoAaIH',
            title: 'Apple Watch',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/2G7iGOLypXNlzIVkGzvBSa/771d75ed980fa9b5c0432151691d6d74/Apple_Watch.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Apple Watch',
            },
            tagline:
              'Regardez des vidéos Youtube sur un très, très, très petit écran.',
            link: {
              href: 'https://www.backmarket.be/fr-be/l/apple-watch-reconditionnee/92caf545-c033-409f-bcb0-d3cfec8af49d',
            },
          },
          {
            id: '3dv8RyJjIeyFp43AcYiQOy',
            title: 'Casques et écouteurs',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/7etWTw6VZf0Kg1FBXbptQr/4764444f9c5a2c56393bc425b0ab58b5/beats_headphones.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Beats headphones',
            },
            tagline: '"La musique adoucit les prix."',
            link: {
              href: 'https://www.backmarket.be/fr-be/l/casque-ecouteurs-reconditionne/f1c6b13e-f93c-4a62-b394-639af7883615',
            },
          },
          {
            id: '3gkYIMwqEdHrKAd2Lda6VW',
            title: 'Consoles et Jeux Videos',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/23RNOpyMw2YvdI5HkCrd9Q/b910a8e7cbc2f771042a21aa25470e4a/1584620697.1677275.jpg',
              width: 400,
              height: 400,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/6viRKAOxrCX9KeZdQ1edi4/e1a3be13e6602e228e5c9c951c5be14f/PS_5_Console.png',
              widthMobile: 200,
              heightMobile: 200,
              alt: 'PlayStation 5 ',
            },
            tagline:
              "De la Game Boy à la Xbox one (sans passer par la Dreamcast, c'est promis).",
            link: {
              href: 'https://www.backmarket.be/fr-be/l/consoles-et-jeux-video/0a092eda-8a90-4b60-85bd-8a3123b2fd84',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '5fxCFx4givF8y8cD0Bc5Hf',
      type: 'PressCarousel',
      props: {
        technicalName: 'BE - Press Carousel',
        subtitle: 'En bien ;)',
        title: 'Ils parlent de nous !',
        articles: [
          {
            id: '6iuOVNEcoFx1cJOK0DDku9',
            text: 'Back Market : "Plus le reconditionné prend de place, moins il y en a pour le neuf"',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4ZdPXdgI9em5rZ8AAqWhgA/480610e82865da2a02223edf0e8d913d/infodurablelogo.png',
              width: 253,
              height: 133,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/4ZdPXdgI9em5rZ8AAqWhgA/480610e82865da2a02223edf0e8d913d/infodurablelogo.png',
              widthMobile: 253,
              heightMobile: 133,
              alt: 'ID BE',
            },
            link: {
              href: 'https://www.linfodurable.fr/conso/back-market-plus-le-reconditionne-prend-de-place-moins-il-y-en-pour-le-neuf-8315',
            },
            publicationDate: '07/2019',
          },
          {
            id: '17xHF2eQvTAwD3piYN6tFw',
            text: "Back Market lève 110 millions d'euros",
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/7lBGhl938AfZRtECo6PByx/38d548759f9e833cda0b015921981e0d/les_echos.jpg',
              width: 400,
              height: 133,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/7lBGhl938AfZRtECo6PByx/38d548759f9e833cda0b015921981e0d/les_echos.jpg',
              widthMobile: 400,
              heightMobile: 133,
              alt: 'Les Echos',
            },
            link: {
              href: 'https://www.lesechos.fr/industrie-services/conso-distribution/back-market-leve-110-millions-deuros-1200396',
            },
            publicationDate: '05/2020',
          },
          {
            id: 'ThOenj975PqiLv31k9Fxe',
            text: "Acheter d'occasion ou reconditionné: Quelles sont les différences? Comment choisir?",
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/38si3W5peQGgMWf8IdSc55/d038c4bc4b8a6301782e1d3d5f092cd8/a6601901c5b425e4050e9f253ec0a551d29f5e3c.jpeg',
              width: 320,
              height: 99,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/38si3W5peQGgMWf8IdSc55/d038c4bc4b8a6301782e1d3d5f092cd8/a6601901c5b425e4050e9f253ec0a551d29f5e3c.jpeg',
              widthMobile: 320,
              heightMobile: 99,
              alt: 'BE - LE VIF weekend ',
            },
            link: {
              href: 'https://weekend.levif.be/lifestyle/news/acheter-d-occasion-ou-reconditionne-quelles-sont-les-differences-comment-choisir/article-normal-803413.html?cookie_check=1648140333',
            },
            publicationDate: '08/18',
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '64920jB6cp46rH47KhXSAb',
      type: 'Faq',
      props: {
        title: 'Autre chose ?',
        subtitle: 'Les 4 questions qu’on nous pose le plus souvent.',
        questions: [
          {
            id: '4E1GPKMbv8tYWHZi4LJ8Xk',
            title:
              'Comment être sûr que mon appareil ne tombera pas en panne dans 1 mois ?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Du processus de présélection de tous les vendeurs aux "commandes mystères" passées par le personnel technique de Back Market (sous des noms de code secrets, bien sûr), nous nous donnons à 110 % pour nous assurer que la technologie proposée correspond à nos promesses. De plus, chaque appareil possède une garantie contractuelle de 12 mois et vous avez 30 jours pour changer d\'avis.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '1y2abVVxOzx0cv4vBhyV0k',
            title: 'Quelle est la différence entre reconditionné et occasion ?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Vu de l’extérieur, un smartphone reconditionné ressemble et fonctionne aussi bien qu’un neuf. Mais c’est ce qu’il y a à l’intérieur qui compte vraiment. Les appareils reconditionnés (smartphones, tablettes, ordinateurs portables et PC) ont beaucoup moins d’impact environnemental que les neufs.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '5qSWuMZBa9UUanjW6mna6F',
            title: 'Attendez une minute… vous vendez quoi d’autre ?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        "Il existe bien des façons d'offrir un peu de répit à la planète. Et en matière de technologie, nous sommes bien plus que des smartphones reconditionnés avec de belles gueules. Des consoles de jeux aux fers à friser, en passant par les baby phones et machines expresso (bonjour aux nouveaux parents), nous avons certainement ce qu’il vous faut.",
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '6RM0zKBNdzMplJnWgZYpSR',
            title:
              'Le T-800 dans Terminator 2 : Le jugement dernier (1991) était-il techniquement du « reconditionné » ?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        "Il nous faudra peut-être soumettre Skynet au processus de certification avant de donner une réponse définitive. Mais nous avons l’intuition que oui, c'est bien un scénario du genre « je reviendrai ».",
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
        ],
        rendering: 'HTML_ONLY',
        ctaLink: {
          link: {
            href: 'https://www.backmarket.be/fr-be/help',
          },
          label: 'Trouver des réponses',
        },
      },
      hiddenDevices: [],
    },
  ],
  type: 'core',
  title: 'Home',
  meta: {
    title: 'le (super) marché du reconditionné',
    description:
      'Profitez de produits reconditionnés sur Back Market, jusqu’à 70% moins cher que le neuf ! Livraison standard offerte - Garantie contractuelle 12 mois minimum - Paiement sécurisé en 3 ou 4X',
    image: {
      src: 'https://images.ctfassets.net/mmeshd7gafk1/5BDjAiB7WVkFJcE5MXLNy4/9becafd6d036bfb6e2cbebae9592e460/SEO-Website-Thumbnail.png',
      width: 516,
      height: 258,
      srcMobile: '',
      widthMobile: 0,
      heightMobile: 0,
      alt: '',
    },
    alternateLinks: [
      {
        country: 'FR',
        locale: 'fr-FR',
      },
      {
        country: 'DE',
        locale: 'de-DE',
      },
      {
        country: 'BE',
        locale: 'fr-BE',
      },
      {
        country: 'ES',
        locale: 'es-ES',
      },
      {
        country: 'IT',
        locale: 'it-IT',
      },
      {
        country: 'GB',
        locale: 'en-GB',
      },
      {
        country: 'AT',
        locale: 'de-AT',
      },
      {
        country: 'NL',
        locale: 'nl-NL',
      },
      {
        country: 'US',
        locale: 'en-US',
      },
      {
        country: 'FI',
        locale: 'fi-FI',
      },
      {
        country: 'PT',
        locale: 'pt-PT',
      },
      {
        country: 'IE',
        locale: 'en-IE',
      },
      {
        country: 'GR',
        locale: 'el-GR',
      },
      {
        country: 'SK',
        locale: 'sk-SK',
      },
      {
        country: 'SE',
        locale: 'sv-SE',
      },
      {
        country: 'JP',
        locale: 'ja-JP',
      },
      {
        country: 'AU',
        locale: 'en-AU',
      },
    ],
  },
  badge: '',
  titleSeo: 'le (super) marché du reconditionné',
  titleBreadcrumb: 'Home',
  banner: {},
  textSeo: null,
  hideFromSearchEngine: false,
  parentPageInfos: null,
  pageType: 'core',
  pageName: 'home-duplicated',
}

export default HOME_FALLBACK_DATA
